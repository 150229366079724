import React from 'react'
import styled from 'styled-components'

export default ({ image, title, description, date }) => {
  return (
    <ActivityCard>
      <Image
        image={image.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      {/*
        <Included>{date}</Included>
        */}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </ActivityCard>
  )
}

const ActivityCard = styled.div`
  flex-direction: column;
  margin: 20px 20px 0;
  width: 350px;
  display: flex;
  justify-content: flex-start;
  background: white;
  border-radius: 6px;
`
// const Included = styled.p`
//   margin: -180px 0 145px auto;
//   padding: 17px 15px 17px 20px;
//   font-size: 10pt;
//   color: ${props => props.theme.primaryColor};
//   line-height: 0;
//   background-color: rgba(255, 255, 255, 0.9);
//   border-radius: 17px 0 0 17px;
//   font-style: italic;
//   font-weight: 400;
// `
const Image = styled.div`
  height: 200px;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 6px 6px 0 0;
`
const Title = styled.h2`
  margin: 25px 20px 10px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 14pt;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
  }
`
const Description = styled.div`
  margin: 0 20px 30px;
  padding: 0;
  height: 210px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 12pt;
  line-height: 18pt;
  color: ${(props) => props.theme.secondaryColor};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
  }
`
