import React from 'react'
import styled from 'styled-components'

import { ResponsiveCarousel } from 'components/Carousel'
import Heading from 'components/Heading'

import CampCard from './CampCard'

const config = [
  { width: 0, items: 1 },
  { width: 800, items: 2 },
  { width: 1200, items: 3 },
]

export default ({ activities, title, src }) => (
  <Container image={src}>
    <Heading align="center">{title}</Heading>
    <Carousel
      breakpoints={config}
      slides={activities}
      interval={8000}
      dotComponent={Dot}
      slideRenderer={(cards) =>
        cards.map((slideProps, i) => <CampCard key={i} {...slideProps} />)
      }
    />
  </Container>
)

const Container = styled.div`
  margin: 20px 0 0;
  padding: 50px 0 0;
  background-color: ${(props) => props.theme.purple};
  background-image: url(${(props) => props.image});
`
const Carousel = styled(ResponsiveCarousel)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 50px;

  @media (max-width: 1025px) {
    margin: 0 0 50px;
  }
`

const Dot = styled(ResponsiveCarousel.Dot)`
  border: 1px solid white;
  background-color: ${(props) => (props.isActive ? '#e0e4fb' : 'transparent')};
`
