import React from 'react'
import styled from 'styled-components'
import Player from 'react-player'

import OverlayBottom from 'components/OverlayBottom'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

const Title = styled(Heading)`
  text-transform: uppercase;
`

export default ({ color, title, content, src }) => {
  return (
    <OverlayBottom src={src}>
      <Title color={color} align="center">
        {title}
      </Title>
      <Markdown color={color} children={content} />
      <Container>
        <Player
          height="auto"
          url="https://www.facebook.com/CasaCastilloNWA/videos/2053220718226402/"
        />
      </Container>
    </OverlayBottom>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  height: auto;

  @media (max-width: ${(props) => props.theme.phone}px) {
    margin-top: 20px;
  }
`
